import { faBars, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Disclosure } from '@headlessui/react'
import { Amplify, Auth } from 'aws-amplify'
import { getCognitoId, getVisitorId, removeCognitoId } from 'components/util/UserId'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react' // useContext
import { useDispatch } from 'react-redux'
import { updateSpoofMode } from 'state/actions/uiAction'
import styled from 'styled-components'
import LinkRoute from '../../ui/link/Link'
import NavbarMenu from '../../ui/navbar/NavbarMenu'
// import { Builder } from '@builder.io/react'
import LinkButton, { LinkButtonProp } from '../common/button'

import mobileHeaderWave from 'images/navbar-wave-2-mobile.svg'
import desktopHeaderWave from 'images/navbar-wave-2.svg'
// import { BuilderCtx } from '../layouts/PageLayout'

//@ts-ignore
import awsconfig from '../../../aws-exports'
Amplify.configure(awsconfig)

import './index.css'
import { Helmet } from 'react-helmet'
interface Navigation {
  navName: string
  link: string
}

interface Props {
  backgroundColor: string
  fontColor: string
  buttonOneProp: LinkButtonProp
  buttonTwoProp: LinkButtonProp
  logo: any
  navigation: Navigation[]
}

const StyledHeader = styled.header`
  &::before {
    position: absolute;
    content: '';
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: ${props => props.backgroundColor};
    -webkit-mask-image: url(${desktopHeaderWave});
    -webkit-mask-position: center bottom;
    -webkit-mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
  }
`

const HeaderWithWave = ({ navigation, logo, buttonOneProp, buttonTwoProp, fontColor, backgroundColor }: Props) => {
  console.log('Header::Navigations', navigation)
  console.log('Header::ButtonProps', buttonOneProp, buttonTwoProp)
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [showNavBar, setShowNavBar] = useState(true)
  const [_visitorId, setVisitorId] = useState('')
  const [_cognitoId, setCognitoId] = useState('')
  const dispatch = useDispatch()
  const dogDetailsPage = 'dog-details'
  if (buttonOneProp?.buttonUrl) buttonOneProp.buttonUrl = `/signup/${dogDetailsPage}`

  // const BuilderComponentsStore = useContext(BuilderCtx)
  // const { builderContext, setBuilderContext }: any = BuilderComponentsStore

  useEffect(() => {
    let lastScroll: number

    const controlNavbar = () => {
      if (window.scrollY > 0 && window.scrollY >= lastScroll) {
        setShowNavBar(false)
      } else {
        setShowNavBar(true)
      }
      lastScroll = window.scrollY
    }

    window.addEventListener('scroll', controlNavbar)
    return () => {
      window.removeEventListener('scroll', controlNavbar)
    }
  }, [])

  useEffect(() => {
    const checkIfSignedIn = async () => {
      const username = await getCognitoId()
      if (username) {
        setIsSignedIn(true)
      } else {
        setIsSignedIn(false)
      }
    }

    document.querySelector('body')?.addEventListener('mouseover', checkIfSignedIn)

    return () => {
      document.querySelector('body')?.removeEventListener('mouseover', checkIfSignedIn)
    }
  }, [])

  useEffect(() => {
    if (isSignedIn) {
      if (!navigation.some(nav => nav.navName === 'Rewards')) {
        navigation.push({ navName: 'Rewards', link: '/account/rewards' })
      }
    }
  }, [navigation, isSignedIn])

  const handleSignOut = async () => {
    try {
      dispatch(updateSpoofMode(false, '', ''))
      await Auth.signOut()
      setIsSignedIn(false)
      removeCognitoId()
      // setBuilderContext({'isLoggedIn':false})
    } catch (err: any) {
      console.log(err, 'error')
    }
  }

  const renderDesktopHeaderNavs = (side: string) => {
    const midPoint = Math.ceil(navigation.length / 2)
    const slicedNavs = side === 'left' ? navigation.slice(0, midPoint) : navigation.slice(midPoint, navigation.length)
    console.log(`side ${side}, mid point - ${midPoint}`, slicedNavs)

    return slicedNavs.map((navItem, i) => {
      return (
        <LinkRoute
          key={`${side}-${i}`}
          href={navItem.link}
          className="hover-bg-color:hover mx-2 text-sm"
          style={{ color: fontColor }}
        >
          {navItem.navName}
        </LinkRoute>
      )
    })
  }

  const leftSideNavs = renderDesktopHeaderNavs('left')
  const rightSideNavs = renderDesktopHeaderNavs('right')
  // let maxNavNumberPerSide = (leftSideNavs.length > rightSideNavs.length) ? leftSideNavs.length: rightSideNavs.length;
  // maxNavNumberPerSide = maxNavNumberPerSide === 1 ? 2: maxNavNumberPerSide;

  const scrollHandler = () => {
    document.querySelector('#cookiebanner-mask')?.remove()
    document.querySelector('.cookiebanner')?.remove()
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0)
    }
  }, [])

  useEffect(() => {
    const callIdsForClarity = async () => {
      setVisitorId(await getVisitorId())
      setCognitoId(await getCognitoId())
    }

    callIdsForClarity()
  }, [])

  return (
    <>
      <div className={'header-wrapper'}>
        <Disclosure as="nav" className="relative min-h-[68px] md:hidden">
          {/* header-fixed */}
          {({ open }) => (
            <div
              className="relative z-30"
              style={{
                transition: 'top 0.5s',
                top: open || showNavBar ? '0px' : '-144px',
                height: open || showNavBar ? 'auto' : '0px',
                transform: 'translateZ(0.1)',
                WebkitMaskImage: `url(${mobileHeaderWave})`,
                WebkitMaskPosition: 'center bottom',
                WebkitMaskSize: 'cover'
              }}
            >
              <Helmet
                bodyAttributes={{
                  class: 'content-page'
                }}
              >
                {/* {process.env.GATSBY_CLARITY_KEY && (
                  <script type="text/javascript">{`
                  (function(c,l,a,r,i,t,y){
                      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                  })(window, document, "clarity", "script", "${process.env.GATSBY_CLARITY_KEY}");
                  ${(cognitoId || visitorId) && `window.clarity("identify", "${cognitoId}", "${visitorId}");`}
                `}</script>
                )} */}
              </Helmet>
              <div
                className="sticky mx-auto px-2 pb-4 pt-2 md:px-6 lg:px-8"
                style={{ backgroundColor: backgroundColor, color: fontColor }}
              >
                <div className="relative flex h-11 items-center justify-between">
                  <div className="inset-y-0 left-0 mr-2 flex items-center md:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="hover-bg-color inline-flex items-center justify-center rounded-md p-4 py-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      <FontAwesomeIcon
                        icon={open ? faX : faBars}
                        style={{ color: fontColor }}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
                    <div className="flex-shrink-1 flex items-center">
                      <LinkRoute href="/">
                        <img style={{ width: '200px', maxWidth: '100%' }} src={logo} alt="" />
                      </LinkRoute>
                    </div>
                  </div>
                  {!isSignedIn && (
                    <>
                      <LinkRoute
                        date-testid="signup"
                        href={`/signup/${dogDetailsPage}/`}
                        className={`position-create-plan-btn ml-2 inline-block rounded-full border border-transparent bg-white py-2 px-2 font-medium text-indigo-600 shadow hover:bg-indigo-50 md:hidden`}
                        style={{ backgroundColor: 'rgb(0, 102, 133)', color: 'rgb(255, 255, 255)' }}
                        onClick={() => {
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag('event', 'cta_button_click', {
                              page: 'homepage'
                            })
                          }
                        }}
                      >
                        Get Started
                      </LinkRoute>
                      <LinkRoute
                        date-testid="signup"
                        href={`/signup/${dogDetailsPage}/`}
                        className={`position-create-plan-btn ml-2 md:inline-block rounded-full border border-transparent bg-white py-2 px-2 font-medium text-indigo-600 shadow hover:bg-indigo-50 hidden`}
                        style={{ backgroundColor: 'rgb(0, 102, 133)', color: 'rgb(255, 255, 255)' }}
                        onClick={() => {
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag('event', 'cta_button_click', {
                              page: 'homepage'
                            })
                          }
                        }}
                      >
                        Create Plan
                      </LinkRoute>
                    </>
                  )}
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3" style={{ backgroundColor }}>
                  {navigation.map(item => (
                    <Disclosure.Button
                      key={item.navName}
                      as="a"
                      href={item.link}
                      className="hover-bg-color block rounded-md px-3 py-2 text-base font-medium text-gray-300 no-underline"
                      style={{ color: fontColor }}
                    >
                      {item.navName}
                    </Disclosure.Button>
                  ))}
                  <div className="header-spacer" />
                  {isSignedIn ? (
                    <div className="flex w-full items-center justify-end">
                      <LinkRoute
                        href={'/account'}
                        className={`mr-2 inline-block rounded-full border border-transparent bg-white py-2 px-2 text-sm font-medium text-indigo-600 shadow hover:bg-indigo-50`}
                        style={{ backgroundColor: 'rgb(0, 67, 85)', color: 'rgb(255, 255, 255)' }}
                      >
                        Account
                      </LinkRoute>
                      <button
                        type="button"
                        className="inline-block rounded-full border border-transparent bg-white py-2 px-2 text-sm font-medium text-indigo-600 shadow hover:bg-indigo-50"
                        style={{ backgroundColor: 'rgb(241,140,131)', color: 'rgb(0, 67, 85)' }}
                        onClick={handleSignOut}
                      >
                        Sign out
                      </button>
                    </div>
                  ) : (
                    <div className="flex w-full items-center justify-end">
                      <LinkButton
                        buttonColor={buttonOneProp?.buttonColor}
                        buttonUrl={buttonOneProp?.buttonUrl}
                        buttonText={buttonOneProp?.buttonText}
                        buttonTextColor={buttonOneProp?.buttonTextColor}
                        loggedInButtonText={buttonOneProp?.loggedInButtonText}
                        isLink={false}
                        classList={`inline-block text-center bg-white py-2 px-2 mr-2 border border-transparent rounded-full font-medium text-indigo-600 hover:bg-indigo-50 shadow`}
                        onClick={() => {
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag('event', 'cta_button_click', {
                              page: 'homepage'
                            })
                          }
                        }}
                      />
                      <LinkButton
                        buttonColor={buttonTwoProp?.buttonColor}
                        buttonUrl={buttonTwoProp?.buttonUrl}
                        buttonText={buttonTwoProp?.buttonText}
                        buttonTextColor={buttonTwoProp?.buttonTextColor}
                        loggedInButtonText={buttonTwoProp?.loggedInButtonText}
                        isLink={false}
                        classList={`inline-block text-center bg-white py-2 px-2 border border-transparent rounded-full font-medium text-indigo-600 hover:bg-indigo-50 shadow`}
                      />
                    </div>
                  )}
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <StyledHeader
          backgroundColor={backgroundColor}
          className={`relative mt-0 hidden min-h-[115px] md:block`}
          style={{
            color: fontColor,
            transition: 'top 0.5s',
            top: showNavBar ? '0px' : '-164px',
            height: showNavBar ? 'auto' : '0px',
            transform: 'translateZ(0.1)',
            zIndex: 3
          }}
        >
          <nav className="max-w-7 mx-auto" aria-label="Top">
            <div className="flex w-full items-center py-6 px-6">
              <div>
                <LinkRoute href="/">
                  <span className="sr-only">Different Dog</span>
                  <img style={{ width: '200px', maxWidth: '100%' }} src={logo} alt="" />
                </LinkRoute>
              </div>
              {isSignedIn ? (
                <div className="flex w-full">
                  <div className="flex w-full items-center justify-end">
                    <div className={`flex items-center justify-end font-serif text-sm`}>{leftSideNavs}</div>
                    <div className={`flex items-center justify-start font-serif text-sm`}>{rightSideNavs}</div>
                    <div
                      className={`ml-2 mr-2 hidden flex-shrink-0 cursor-pointer rounded-full p-4 text-sm hover:bg-[white] focus:bg-[white] sm:flex`}
                      onClick={() => {
                        if (typeof window.zE !== 'undefined') {
                          window.zE('webWidget', 'show')
                          window.zE('webWidget', 'open')
                        } else {
                          window.setTimeout(() => {
                            window.zE('webWidget', 'show')
                            window.zE('webWidget', 'open')
                          }, 1000)
                        }
                      }}
                    >
                      <StaticImage
                        width={35}
                        className="flex flex-shrink-0"
                        src="../../../images/chat.png"
                        alt="Chat with us"
                      />
                    </div>
                    <NavbarMenu isLogin={isSignedIn} handleSignOut={handleSignOut} />
                  </div>
                </div>
              ) : (
                <div className="flex w-full items-center justify-end">
                  <div className="flex w-2/3 justify-end">
                    <div className={`flex items-center justify-end font-serif text-sm`}>{leftSideNavs}</div>
                    <div className={`flex items-center justify-start font-serif text-sm`}>{rightSideNavs}</div>
                  </div>
                  <div
                    className={`ml-2 mr-2 hidden flex-shrink-0 cursor-pointer rounded-full p-4 text-sm hover:bg-[white] focus:bg-[white] sm:flex`}
                    onClick={() => {
                      if (typeof window.zE !== 'undefined') {
                        window.zE('webWidget', 'show')
                        window.zE('webWidget', 'open')
                      } else {
                        window.setTimeout(() => {
                          window.zE('webWidget', 'show')
                          window.zE('webWidget', 'open')
                        }, 1000)
                      }
                    }}
                  >
                    <StaticImage
                      width={35}
                      className="flex flex-shrink-0"
                      src="../../../images/chat.png"
                      alt="Chat with us"
                    />
                  </div>
                  <div className="flex flex-shrink-0">
                    <LinkButton
                      buttonColor={buttonOneProp?.buttonColor}
                      buttonUrl={buttonOneProp?.buttonUrl}
                      buttonText={buttonOneProp?.buttonText}
                      buttonTextColor={buttonOneProp?.buttonTextColor}
                      loggedInButtonText={buttonOneProp?.loggedInButtonText}
                      isLink={false}
                      classList={`inline-block text-center mr-4 bg-white py-2 px-2 border border-transparent rounded-full font-medium text-indigo-600 hover:bg-indigo-50 shadow`}
                      onClick={() => {
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'cta_button_click', {
                            page: 'homepage'
                          })
                        }
                      }}
                    />
                    <LinkButton
                      buttonColor={'transparent'}
                      buttonUrl={buttonTwoProp?.buttonUrl}
                      buttonText={buttonTwoProp?.buttonText}
                      buttonTextColor={buttonTwoProp?.buttonTextColor}
                      loggedInButtonText={buttonTwoProp?.loggedInButtonText}
                      isLink={false}
                      classList={`inline-block text-center bg-white py-2 px-2 border-2 border-base rounded-full font-medium text-indigo-600 hover:bg-indigo-50 shadow`}
                    />
                  </div>
                </div>
              )}
            </div>
          </nav>
        </StyledHeader>
      </div>
    </>
  )
}

export default HeaderWithWave
